import $ from 'jquery';
$(document).ready(function() {

	$('.js-m-tab-switch').on('change', function() {

	    var target = $(this).val();

	    /*console.log(target);
	    // Show target tab group
	    location.href = target;*/
      console.log(target);
      document.querySelectorAll('.c-contact--column').forEach(function (el) {
        el.classList.toggle('active');
      });
	});
})
