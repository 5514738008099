import $ from 'jquery';
import './slick.min';

$(document).ready(function() {
  var tallest = 0;
  $('.packages .c-card__container').each(function () {
    if($(this).height() > tallest) {
      tallest = $(this).height(); 
    }
  });
  $('.packages .c-card__container').height(tallest);

  $('.c-form .tooltip-group').each(function () {
		$(this).insertAfter($(this).parent().parent().prev().find('.form-check label'));
	});

	document.querySelectorAll('.tooltip-box').forEach(function (elem) {
		var boxHeight = elem.clientHeight + 8;
		console.log(boxHeight);
		elem.style.top = '-' + boxHeight + 'px';
	});

	$('.tooltip-group').each(function () {
		$(this).hover(function (event) {
			$(this).find('.tooltip-box').toggleClass('show');
		});
	});

  $('.ty-step-images').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    infinite: false,
    centerMode: true,
    speed: 500,
    asNavFor: '.ty-steps'
  });
  $('.ty-steps').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: false,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    asNavFor: '.ty-step-images'
  });
});




		

 