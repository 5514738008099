import $ from 'jquery';
$(document).ready(function() {
	$('[data-to]').click(function() {
		var to = $(this).attr('data-to');
		$.scrollAnim(to);
		return true;
	});
	$.scrollAnim = function(to) {
		if (to.match(/^(-|)[0-9]+$/)) {
			$('body').animate({'scrollTop': to}, 500);
		} else {
			$('body').animate({'scrollTop': $(to).offset().top}, 500);
		}
	};

	$('[data-bottom="footer"]').click(function() {
		$('html,body').animate({
			scrollTop: $("#contactForm").offset().top
		},"slow");
	});

	$('[data-bottom="contact"]').click(function() {
		$('html,body').animate({
			scrollTop: $("#contact").offset().top
		},"slow");
	});

	$('[data-id="vacancies"]').click(function() {
		$('html,body').animate({
			scrollTop: $("#vacancies").offset().top
		},"slow");
	});

	$('[data-id="news"]').click(function() {
		$('html,body').animate({
			scrollTop: $("#news").offset().top
		},"slow");
	});
})
