import $ from 'jquery';
$(document).ready(function() {

	var socialToggle = $(".js-social-toggle");
	var socials = $(".c-socials");

	socialToggle.on('click', function (e) {
    	e.stopPropagation();
    	e.preventDefault();
    	socials.toggleClass('is-open');
    	return false;
  	});
})
