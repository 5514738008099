/* global $ */
import $ from 'jquery';
$(document).ready(function () {
  var transform = 'left'
  var prefixes = 'transform WebkitTransform MozTransform OTransform msTransform'.split(' ')
  $.fn.onInViewport = function (func) {
    var $element = $(this)
    var inViewPort = function () {
      var offset = $element.offset()
      var size = {
        height: $element.height(),
        width: $element.width()
      }
      var windowsize = {
        height: $(window).height(),
        width: $(window).width()
      }
      var vertical = (offset.top - windowsize.height < $(document).scrollTop() && ($(document).scrollTop()) < offset.top + size.height)
      var horizontal = (offset.left - windowsize.width < $(document).scrollLeft() && ($(document).scrollLeft()) < offset.left + size.width)

      if (vertical && horizontal) {
        func(true)
      } else {
        func(false)
      }
    }
    $(document).scroll(function () {
      inViewPort()
    })
    $(window).resize(function () {
      inViewPort()
    })
    inViewPort()
  }

  for (var i = 0; i < prefixes.length; i++) {
    if (document.createElement('div').style[prefixes[i]] !== undefined) {
      transform = prefixes[i]
      if (transform === 'WebkitTransform') {
        transform = '-webkit-transform'
      } else if (transform === 'MozTransform') {
        transform = '-moz-transform'
      } else if (transform === 'OTransform') {
        transform = '-o-transform'
      } else if (transform === 'msTransform') {
        transform = '-ms-transform'
      }
    }
  }

  var left = function (element, left) {
    if (transform !== 'left') {
      return $(element).css('transition', '').css(transform, 'translateX(' + left + 'px)')
    }
    return $(element).css('transition', '').css(transform, left)
  }
  var fixleft = function (element, left) {
    if (transform !== 'left') {
      return $(element).css('transition', 'none').css(transform, 'translateX(' + left + 'px)')
    }
    return $(element).css('transition', 'none').addClass('.c-carousel__inner--off').css(transform, left)
  }
  var readjust = function ($carousel, opt, $carouselInner) {
    var itemsInScreen = Math.floor(($carousel.width() + opt.itemSpacing) / (opt.itemWidth + opt.itemSpacing))
    if (itemsInScreen >= 3) {
      if (!$carousel.data('enabled')) {
        fixleft($carouselInner, -((Math.floor(opt.items / 2) - 1) * (opt.itemWidth + opt.itemSpacing)))
        $carousel.data('enabled', true)
      }
      $carousel.find('.additional').show()
      $carousel.find('.c-carousel__inner').children('.c-card').each(function (index) {
        var $card = $(this)

        if (opt.height < $card.height()) {
          opt.height = $card.height()+2
          opt.contentHeight = $card.find('.c-card__container').height() + 60
        }

        left($card, index * (opt.itemWidth + opt.itemSpacing))
      })
    } else {
      if ($carousel.data('enabled')) {
        fixleft($carouselInner, 0)
        $carousel.data('enabled', false)
      }
      $carousel.find('.additional').hide()
      $carousel.find('.c-carousel__inner').children('.c-card:not(.additional)').each(function (index) {
        var $card = $(this)

        if (opt.height < $card.height()) {
          opt.height = $card.height()+2
          opt.contentHeight = $card.find('.c-card__container').height() + 60
        }

        left($card, index * (opt.itemWidth + opt.itemSpacing))
      })
    }
  }
  $('.c-carousel').each(function () {
    var $carousel = $(this)
    var $carouselInner = $(this).find('.c-carousel__inner')
    var $firstChild = $carousel.find('.c-carousel__inner').children().first()
    var $lastChild = $carousel.find('.c-carousel__inner').children().last()
    var $carouselIndicator = $carousel.find('.c-carousel__indicator')
    var $carouselPoint = $carousel.find('.c-carousel__indicatorPoint')
    var $carouselPointAdd = $carousel.find('.c-carousel__indicatorPointAdd')
    var opt = {
      height: 0,
      width: $carousel.width(),
      items: $carouselInner.children().length,
      itemWidth: parseInt($(this).attr('data-itemwidth')) || 350,
      itemSpacing: 20,
      start: 0,
      contentHeight: 0
    }
    $(this).find('.c-card').width(opt.itemWidth)
    var needed = Math.ceil(opt.items / 2)
    opt.start = opt.start + needed
    var i = opt.start - 1
    var itemsInScreen = Math.floor((opt.width + opt.itemSpacing) / (opt.itemWidth + opt.itemSpacing))
    $carousel.width(itemsInScreen * (opt.itemWidth + opt.itemSpacing) - opt.itemSpacing)
    $carouselPoint.width(itemsInScreen / opt.items * opt.itemWidth)
    $carouselPointAdd.width(itemsInScreen / opt.items * opt.itemWidth)
    if (itemsInScreen < 3) {
      $carousel.width('100%')
      $carousel.css('overflow-x', 'scroll')
      $carouselIndicator.hide()
      $carousel.find('.c-carousel__button').hide()
      $carousel.data('enabled', true)
    } else {
      $carousel.width(itemsInScreen * (opt.itemWidth + opt.itemSpacing) - opt.itemSpacing)
      $carouselIndicator.show()
      $carousel.css('overflow-x', 'hide')
      $carousel.find('.c-carousel__button').show()
      $carousel.data('enabled', false)
    }
    var carouselPointerLeft = function (i) {
      var x = i
      if (x < opt.start - 1) {
        x = opt.items + x
      } else if (x > opt.start + opt.items - 1) {
        x = x - (opt.start + opt.items) + 1
      }
      var width = (itemsInScreen / opt.items * opt.itemWidth)
      var _left = (((x - opt.start) / opt.items) * opt.itemWidth)
      var widthLeft = (width + _left)
      if (widthLeft > opt.itemWidth || _left < 0) {
        if (_left > 0) {
          left($carouselPoint, ((x - opt.start) / opt.items) * opt.itemWidth)
          $carouselPointAdd.show()
          left($carouselPointAdd, -(opt.itemWidth) + _left)
        } else {
          left($carouselPointAdd, ((x - opt.start) / opt.items) * opt.itemWidth)
          $carouselPointAdd.show()
          left($carouselPoint, opt.itemWidth + _left)
        }
      } else {
        left($carouselPointAdd, -(opt.itemWidth) + _left)
        setTimeout(function () {
          $carouselPointAdd.hide()
        }, 500)
        left($carouselPoint, ((x - opt.start) / opt.items) * opt.itemWidth)
      }
    }
    carouselPointerLeft(i)
    $(window).resize(function () {
      $carousel.width('100%')
      window.requestAnimationFrame(function () {
        var itemsInScreen = Math.floor(($carousel.width() + opt.itemSpacing) / (opt.itemWidth + opt.itemSpacing))
        if (itemsInScreen < 3) {
          $carousel.width('100%')
          $carousel.css('overflow-x', 'scroll')
          $carouselIndicator.hide()
          $carousel.find('.c-carousel__button').hide()
          $carousel.find('.additional').hide()
          $carousel.scrollLeft(0)
        } else {
          $carousel.width(itemsInScreen * (opt.itemWidth + opt.itemSpacing) - opt.itemSpacing)
          $carouselIndicator.show()
          $carousel.find('.c-carousel__button').show()
          $carousel.css('overflow-x', 'hidden')
          $carousel.find('.additional').show()
          $carousel.scrollLeft(0)
        }
        $carouselPoint.width((itemsInScreen / opt.items * opt.itemWidth))
        $carouselPointAdd.width((itemsInScreen / opt.items * opt.itemWidth))
        readjust($carousel, opt, $carouselInner)
      })
    })
    fixleft($carouselInner, -((needed - 1) * (opt.itemWidth + opt.itemSpacing)))

    $carousel.find('.c-carousel__inner').children('.c-card').each(function (index, card) {
      var $card = $(card).clone()
      $card.addClass('additional')
      if (index < needed) {
        $lastChild.parent().append($card)
      }
      if (index > opt.items - 1 - needed) {
        $firstChild.before($card)
      }
    })
    readjust($carousel, opt, $carouselInner)
    $carousel.find('.c-carousel__inner').children('.c-card').each(function () {
      var $card = $(this).find('.c-card__container')
      $card.css('height', opt.contentHeight)
    })
    var moveToNext = function () {
      i++
      left($carouselInner, -(i * (opt.itemWidth + opt.itemSpacing)))
      carouselPointerLeft(i)
      if (i === opt.start + needed + 2) {
        setTimeout(function () {
          i = 2
          fixleft($carouselInner, -(i * (opt.itemWidth + opt.itemSpacing)))
        }, 500)
      }
    }
    var moveToPrev = function () {
      i--
      left($carouselInner, -(i * (opt.itemWidth + opt.itemSpacing)))
      carouselPointerLeft(i)
      if (i === 1) {
        setTimeout(function () {
          i = opt.start + needed + 1
          fixleft($carouselInner, -(i * (opt.itemWidth + opt.itemSpacing)))
        }, 500)
      }
    }
    $carousel.onInViewport(function (inview) {
      if (inview) {
        $(document).off('keydown').on('keydown', function (e) {
          if (e.which === 39) {
            moveToNext()
          } else if (e.which === 37) {
            moveToPrev()
          }
        })
      } else {
        $(document).off('keydown')
      }
    })
    $carousel.height(opt.height)
    $carouselInner.height(opt.height)
    $carousel.find('.c-carousel__button--prev').click(function () {
      moveToPrev()
    })
    $carousel.find('.c-carousel__button--next').click(function () {
      moveToNext()
    })
  })
})
