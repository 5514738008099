import $ from 'jquery';
$(document).ready(function() {
	$.togglePane = function(pane) {
		$('[data-pane="'+pane+'"]').toggleClass('o-pane--hidden');
	};
	$.hidePane = function(pane) {
		$('[data-pane="'+pane+'"]').addClass('o-pane--hidden');
	};
	$.showPane = function(pane) {
		$('[data-pane="'+pane+'"]').removeClass('o-pane--hidden');
	};
	$('[data-toggle-pane]').click(function() {
		$.togglePane($(this).attr('data-toggle-pane'));
		return false;
	});
	$('[data-hide-pane]').click(function() {
		$.hidePane($(this).attr('data-hide-pane'));
		return false;
	});
	$('[data-show-pane]').click(function() {
		$.showPane($(this).attr('data-show-pane'));
		return false;
	});
})
